import { compareKmkId, compareRouteShortName } from "../utils";

import { KmkId } from "./KmkId";
import { Link } from "react-router-dom";
import React from "react";
import { RouteShortNameBox } from "./RouteShortNameBox";
import { RouteShortNameBoxPlaceholder } from "./RouteShortNameBoxPlaceholder";
import Spinner from "react-bootstrap/Spinner";
import { StarIcon } from "./StarIcon";
import { StopLink } from "./StopLink";
import Table from "react-bootstrap/Table";
import { Vehicle } from "../hooks/useActiveVehicles";
import { useFavoriteVehicles } from "../hooks/useFavoriteVehicles";
import { useLocalStorage } from "usehooks-ts";

interface Order {
  by: "kmk_id" | "route_short_name";
  direction: "asc" | "desc";
}

interface OrderLinkProps extends React.PropsWithChildren {
  by: "kmk_id" | "route_short_name";
  order: Order;
  setOrder: (order: Order) => void;
}

function OrderLink({ by, order, setOrder, children }: OrderLinkProps) {
  const handleClick = () => {
    const direction =
      order.by === by && order.direction === "asc" ? "desc" : "asc";
    setOrder({ by, direction });
  };

  return (
    <span className="link-primary" role="button" onClick={handleClick}>
      {children}
      {order.by === by && " " + (order.direction === "asc" ? "↓" : "↑")}
    </span>
  );
}

interface VehiclesListProps {
  vehicles: Vehicle[];
  loading: boolean;
}

function sortVehicles(vehicles: Vehicle[], order: Order) {
  // TODO: unit tests
  const copy = Array.from(vehicles);
  if (order.by === "kmk_id") {
    copy.sort((a, b) => compareKmkId(a?.kmk_id, b?.kmk_id));
  } else if (order.by === "route_short_name") {
    copy.sort((a, b) =>
      compareRouteShortName(
        a.route_short_name ?? " ",
        b.route_short_name ?? " "
      )
    );
  }
  if (order.direction === "desc") {
    copy.reverse();
  }
  return copy;
}

export function VehiclesList({ vehicles, loading }: VehiclesListProps) {
  const [order, setOrder] = useLocalStorage<Order>("vehicles_list_order", {
    by: "route_short_name",
    direction: "asc",
  });

  const sortedVehicles = React.useMemo(
    () => sortVehicles(vehicles, order),
    [vehicles, order]
  );

  const { isFavorite } = useFavoriteVehicles();

  return (
    <Table size="sm" striped bordered hover className="table-td-align-middle">
      <thead>
        <tr>
          <th className="text-center" style={{ width: "18%" }}>
            <OrderLink by="kmk_id" order={order} setOrder={setOrder}>
              Numer taborowy
            </OrderLink>
          </th>
          <th className="text-center" style={{ width: "8%" }}>
            <OrderLink by="route_short_name" order={order} setOrder={setOrder}>
              Linia
            </OrderLink>
          </th>
          <th style={{ width: "30%" }}>Kierunek</th>
          <th>Przystanek</th>
          <th className="text-center" style={{ width: "15%" }}>
            Brygada
          </th>
        </tr>
      </thead>
      <tbody>
        {sortedVehicles.map((vehicle) => (
          <tr key={vehicle.key}>
            <td className="text-center">
              {vehicle.full_kmk_id !== null && (
                <KmkId kmk_id={vehicle.full_kmk_id} />
              )}
              {vehicle.kmk_id !== null && isFavorite(vehicle.kmk_id) && (
                <StarIcon />
              )}
            </td>
            <td className="text-center">
              {vehicle.route_short_name ? (
                <RouteShortNameBox routeShortName={vehicle.route_short_name} />
              ) : (
                <RouteShortNameBoxPlaceholder />
              )}
            </td>
            <td>
              {vehicle.trip_headsign && (
                <StopLink
                  stopName={vehicle.trip_headsign}
                  bold
                  removeNz
                  expandDepotName
                />
              )}
            </td>
            <td>
              {vehicle.stop_name && <StopLink stopName={vehicle.stop_name} />}
            </td>
            <td className="text-center bold">
              <Link to={`/blocks/${vehicle.category}/${vehicle.block_id}`}>
                {vehicle.category}/{vehicle.block_id}
              </Link>
            </td>
          </tr>
        ))}
        {loading && (
          <tr>
            <td colSpan={5} className="py-3 text-center">
              <Spinner animation="border" variant="primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </td>
          </tr>
        )}
        {!loading && vehicles.length === 0 && (
          <tr>
            <td colSpan={5} className="py-3 text-center text-secondary">
              Brak zalogowanych pojazdów.
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}
