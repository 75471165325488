import { useEffect, useState } from "react";

import { BACKEND_BASE_URL } from "../config";
import { compareRouteShortName } from "../utils";
import { useFetch } from "usehooks-ts";

export interface VehicleOnBlock {
  kmk_id: string;
  timestamp: number;
}

export interface Block {
  category: string;
  block_id: number;
  service_id: string;
  start_time: string;
  end_time: string;
  route_short_names: string[];
  is_current: boolean;
  vehicles_on_block: VehicleOnBlock[];
}

export interface Weekdays {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

export interface Service extends Weekdays {
  service_id: string;
  operating: boolean;
}

export interface Stop {
  stop_id: number;
  stop_name: string;
  stop_lat: number;
  stop_lon: number;
}

export interface Variant {
  direction_id: number;
  trip_headsigns: string[];
}

export interface StopPoint {
  stop_point_id: number;
  stop_point_name: string;
  direction_id: number;
}

export interface Shape {
  shape_id: number;
  shape_points: [number, number][];
}

function sortShortRouteNames(blocks: Block[]) {
  return blocks.map((block) => {
    if (block.route_short_names.length === 1) {
      return block;
    }
    const copy = Array.from(block.route_short_names);
    copy.sort(compareRouteShortName);
    return { ...block, route_short_names: copy };
  });
}

function sortStopNames(original: Stop[]) {
  const copy = Array.from(original);
  copy.sort((a, b) => a.stop_name.localeCompare(b.stop_name));
  return copy;
}

export function useRoute(routeShortName: string) {
  const { data, error } = useFetch<{
    carrier: string | null;
    blocks: Block[];
    services: Service[];
    stops: Stop[];
    directions: string[];
    variants: Variant[];
    stop_points: StopPoint[];
    shapes: Shape[];
  }>(`${BACKEND_BASE_URL}/api/routes/${routeShortName}`);

  const [carrier, setCarrier] = useState<string | null>(null);
  const [blocks, setBlocks] = useState<Block[]>([]);
  const [services, setServices] = useState<Service[]>([]);
  const [stops, setStops] = useState<Stop[]>([]);
  const [directions, setDirections] = useState<string[]>([]);
  const [variants, setVariants] = useState<Variant[]>([]);
  const [stopPoints, setStopPoints] = useState<StopPoint[]>([]);
  const [shapes, setShapes] = useState<Shape[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data) {
      setCarrier(data.carrier);
      setBlocks(sortShortRouteNames(data.blocks));
      setServices(data.services);
      setStops(sortStopNames(data.stops));
      setDirections(data.directions);
      setVariants(data.variants);
      setStopPoints(data.stop_points);
      setShapes(data.shapes);
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    // reset state when route changes
    setCarrier(null);
    setBlocks([]);
    setServices([]);
    setStops([]);
    setDirections([]);
    setVariants([]);
    setShapes([]);
    setLoading(true);
  }, [routeShortName]);

  return {
    carrier,
    blocks,
    services,
    stops,
    directions,
    variants,
    stopPoints,
    shapes,
    loading,
    error,
  };
}
