import { Link, useParams } from "react-router-dom";
import { expandDepotName, isInteger, isValidCategory } from "../utils";

import Button from "react-bootstrap/Button";
import { Category } from "../hooks/useBlock";
import Container from "react-bootstrap/Container";
import { Error } from "../components/Error";
import PageTitle from "../components/PageTitle";
import Spinner from "react-bootstrap/Spinner";
import { TripStopTimes } from "../components/TripStopTimes";
import { useTrip } from "../hooks/useTrip";

interface TripInnerProps {
  category: Category;
  blockId: number;
  tripNum: number;
}

function TripInner({ category, blockId, tripNum }: TripInnerProps) {
  const {
    routeShortName,
    tripHeadsign,
    stopTimes,
    prevTripNum,
    nextTripNum,
    loading,
    error,
  } = useTrip(category, blockId, tripNum);

  return (
    <Container>
      {tripHeadsign !== undefined && (
        <PageTitle
          title={`${routeShortName} → ${expandDepotName(tripHeadsign)}`}
        />
      )}
      <h1 className="my-4">
        {tripHeadsign !== undefined && (
          <>
            {routeShortName} &rarr; {expandDepotName(tripHeadsign)}
          </>
        )}
      </h1>
      <div className="col-lg-6 mb-4">
        {error ? (
          <Error error={error} />
        ) : loading ? (
          <Spinner animation="border" variant="primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          <>
            <div className="mb-3">
              {prevTripNum !== null ? (
                <Link
                  to={`/blocks/${category}/${blockId}/trips/${prevTripNum}`}
                  className="me-2"
                  replace
                >
                  <Button variant="outline-primary" className="mb-2">
                    &larr; Poprzedni kurs
                  </Button>
                </Link>
              ) : (
                <Button
                  variant="outline-secondary"
                  className="me-2 mb-2"
                  disabled
                >
                  &larr; Poprzedni kurs
                </Button>
              )}
              <Link to={`/blocks/${category}/${blockId}`} className="me-2">
                <Button variant="outline-primary" className="mb-2">
                  Rozkład brygady
                </Button>
              </Link>
              {nextTripNum !== null ? (
                <Link
                  to={`/blocks/${category}/${blockId}/trips/${nextTripNum}`}
                  replace
                >
                  <Button variant="outline-primary" className="mb-2">
                    Następny kurs &rarr;
                  </Button>
                </Link>
              ) : (
                <Button variant="outline-secondary" className="mb-2" disabled>
                  Następny kurs &rarr;
                </Button>
              )}
            </div>
            <TripStopTimes stopTimes={stopTimes} />
          </>
        )}
      </div>
    </Container>
  );
}

export function Trip() {
  const { category, blockId, tripNum } = useParams();

  if (category === undefined || !isValidCategory(category)) {
    return null;
  }
  if (blockId === undefined || !isInteger(blockId)) {
    return null;
  }
  if (tripNum === undefined || !isInteger(tripNum)) {
    return null;
  }

  return (
    <TripInner
      category={category as Category}
      blockId={Number.parseInt(blockId)}
      tripNum={Number.parseInt(tripNum)}
    />
  );
}
