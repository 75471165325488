import { Block, Service, useBlocks } from "../hooks/useBlocks";
import { useMemo, useState } from "react";

import Alert from "react-bootstrap/Alert";
import { BlocksList } from "../components/BlocksList";
import Container from "react-bootstrap/Container";
import { Counter } from "../components/Counter";
import { Error } from "../components/Error";
import Form from "react-bootstrap/Form";
import PageTitle from "../components/PageTitle";
import { ServiceSelect } from "../components/ServiceSelect";
import Spinner from "react-bootstrap/Spinner";
import { isNightRoute } from "../utils";
import { useFavoriteVehicles } from "../hooks/useFavoriteVehicles";
import { useLocalStorage } from "usehooks-ts";

interface InnerProps {
  blocks: Block[];
  services: Service[];
  timestamp: number | null;
}

const MODES = [
  { id: "all", label: "Wszystkie brygady" },
  { id: "only_joined", label: "Tylko łączenia" },
  { id: "only_night", label: "Tylko linie nocne" },
  { id: "only_favorite", label: "Tylko ulubione pojazdy" },
  { id: "only_more_than_one_vehicle", label: "Tylko więcej niż 1 pojazd" },
] as const;

type Mode = typeof MODES[number]["id"];

function Inner({ blocks, services, timestamp }: InnerProps) {
  const [serviceId, setServiceId] = useState(() => {
    return (
      blocks.find((block) => block.vehicles_on_block.length >= 1) ||
      blocks.find((block) => block.is_current)
    )?.service_id; // any active vehicle or any current block
  });

  const [currentMode, setCurrentMode] = useLocalStorage<Mode>(
    "mode",
    "only_joined"
  );

  const { isFavorite } = useFavoriteVehicles();

  const filteredBlocks = useMemo(() => {
    const filteredBlocks = blocks.filter(
      (block) => block.service_id === serviceId
    );
    switch (currentMode) {
      case "only_joined":
        return filteredBlocks.filter(
          (block) => block.route_short_names.length >= 2
        );
      case "only_night":
        return filteredBlocks.filter((block) =>
          block.route_short_names.some(isNightRoute)
        );
      case "only_favorite":
        return filteredBlocks.filter((block) =>
          block.vehicles_on_block.some((vehicle) => isFavorite(vehicle.kmk_id))
        );
      case "only_more_than_one_vehicle":
        return filteredBlocks.filter(
          (block) => block.vehicles_on_block.length >= 2
        );
      default:
        return filteredBlocks;
    }
  }, [serviceId, currentMode, blocks, isFavorite]);

  return (
    <>
      <div className="mb-3">
        <div className="row">
          <div className="col-lg-6 mb-2">
            <ServiceSelect
              services={services}
              serviceId={serviceId}
              setServiceId={setServiceId}
              disabledFunc={(serviceId) =>
                !blocks.some((block) => block.service_id === serviceId)
              }
            />
          </div>
          <div className="col-lg-6 mb-2">
            {MODES.map((mode) => (
              <Form.Group controlId={`mode_${mode.id}`} key={`mode_${mode.id}`}>
                <Form.Check
                  type="radio"
                  key={mode.id}
                  value={mode.id}
                  label={mode.label}
                  checked={mode.id === currentMode}
                  onChange={() => setCurrentMode(mode.id as Mode)}
                />
              </Form.Group>
            ))}
          </div>
        </div>
        {timestamp && (
          <p>
            Ostatnia aktualizacja: <Counter timestamp={timestamp / 1000} />
          </p>
        )}
      </div>
      {serviceId === undefined ? (
        <Alert variant="secondary">Wybierz dzień tygodnia.</Alert>
      ) : filteredBlocks.length === 0 ? (
        <Alert variant="secondary">Brak brygad.</Alert>
      ) : (
        <BlocksList blocks={filteredBlocks} />
      )}
    </>
  );
}

export function Blocks() {
  const { timestamp, blocks, services, loading, error } = useBlocks(20_000);

  return (
    <Container>
      <PageTitle title="Brygady" />
      <div className="col-lg-8">
        <h1 className="my-4">Brygady</h1>
        {error ? (
          <Error error={error} />
        ) : loading ? (
          <Spinner animation="border" variant="primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          <Inner blocks={blocks} services={services} timestamp={timestamp} />
        )}
      </div>
    </Container>
  );
}
