import "./Delay.css";

interface DelayProps {
  delay: number | null;
}

export function Delay({ delay }: DelayProps) {
  return (
    <div className="delay">
      {delay === null ? null : delay > 0 ? (
        <sup className="bold text-danger">+{delay}</sup>
      ) : delay < 0 ? (
        <sup className="bold text-danger">&ndash;{-delay}</sup>
      ) : delay === 0 ? (
        <sup className="bold text-primary">✓</sup>
      ) : null}
    </div>
  );
}
