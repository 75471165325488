import { useMemo } from "react";
import Map, {
  FullscreenControl,
  GeolocateControl,
  Layer,
  NavigationControl,
  ScaleControl,
  Source,
} from "react-map-gl/maplibre";
import { Shape, Stop } from "../hooks/useRoute";
import { featureCollection, lineString, point } from "@turf/helpers";
import bbox from "@turf/bbox";

interface StopMarkersProps {
  stops: Stop[];
}

function geojsonFromStops(stops: Stop[]) {
  return featureCollection(
    stops.map((s) => point([s.stop_lon, s.stop_lat], s))
  );
}

function StopMarkers({ stops }: StopMarkersProps) {
  const geojson = useMemo(() => geojsonFromStops(stops), [stops]);

  return (
    <Source id="stops" type="geojson" data={geojson}>
      <Layer
        id="stops_circles"
        type="circle"
        paint={{
          "circle-radius": ["interpolate", ["linear"], ["zoom"], 6, 1, 15, 3],
          "circle-color": "black",
          "circle-opacity": 0.5,
        }}
      />
      <Layer
        id="stops_labels"
        type="symbol"
        layout={{
          "text-field": ["get", "stop_name"],
          "text-font": ["Open Sans Bold"],
          "text-size": 12,
          "text-anchor": "bottom",
          "text-offset": [0, -0.5],
        }}
        paint={{
          "text-color": "gray",
          "text-halo-color": "white",
          "text-halo-width": 1.5,
        }}
      />
    </Source>
  );
}

interface ShapesProps {
  shapes: Shape[];
}

function geojsonFromShapes(shapes: Shape[]) {
  return featureCollection(
    shapes.map((shape) =>
      lineString(
        shape.shape_points.map(([lat, lng]) => [lng, lat]),
        shape
      )
    )
  );
}

function Shapes({ shapes }: ShapesProps) {
  const geojson = useMemo(() => geojsonFromShapes(shapes), [shapes]);

  return (
    <Source id="shapes" type="geojson" data={geojson}>
      <Layer
        id="shapes_lines"
        type="line"
        paint={{
          "line-color": "blue",
          "line-width": 3,
          "line-opacity": 0.3,
        }}
      />
    </Source>
  );
}

interface RouteMapProps {
  stops: Stop[];
  shapes: Shape[];
}

export function RouteMap({ stops, shapes }: RouteMapProps) {
  const bounds = useMemo(() => {
    return bbox(geojsonFromStops(stops)) as [number, number, number, number];
  }, [stops]);

  return (
    <div style={{ aspectRatio: 1 }}>
      <Map
        initialViewState={{ bounds, fitBoundsOptions: { padding: 80 } }}
        minZoom={9}
        bearing={0}
        pitchWithRotate={false}
        mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
      >
        <StopMarkers stops={stops} />
        <Shapes shapes={shapes} />
        <NavigationControl />
        <GeolocateControl />
        <FullscreenControl />
        <ScaleControl />
      </Map>
    </div>
  );
}
