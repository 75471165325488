import { useEffect, useState } from "react";

import { BACKEND_BASE_URL } from "../config";
import { useCacheBuster } from "./useCacheBuster";
import { useFetch } from "usehooks-ts";
import { Category } from "./useBlock";

export type Mode = "departures" | "arrivals";

type Status = "PLANNED" | "PREDICTED" | "STOPPING" | "DEPARTED";

export interface Passage {
  passage_id: string;
  route_short_name: string | null;
  trip_headsign: string;
  timestamp: number;
  planned_time: string;
  status: Status;
  delay: number | null;
  old: boolean;
  kmk_ids: string[];
  category: Category;
  block_id: number;
  trip_num: number;
}

export function useStopPassages(
  stopName: string,
  mode: Mode,
  interval: number
) {
  const { cacheBuster } = useCacheBuster(interval);

  const { data, error } = useFetch<{
    passages: Passage[];
  }>(`${BACKEND_BASE_URL}/api/stops/${stopName}/${mode}?t=${cacheBuster}`);

  const [passages, setPassages] = useState<Passage[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data) {
      setPassages(data.passages);
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    // reset state when stop name or mode changes
    setPassages([]);
    setLoading(true);
  }, [stopName, mode]);

  return { passages, loading, error };
}
