import { useEffect, useState } from "react";

import { BACKEND_BASE_URL } from "../config";
import { useCacheBuster } from "./useCacheBuster";
import { useFetch } from "usehooks-ts";

export type Category = "tram" | "bus" | "mobilis";

export interface Point {
  latitude: number;
  longitude: number;
}

export type Path = Point[];

export interface StopTime {
  stop_sequence: number;
  stop_name: string;
  stop_point_num: number | null;
  departure_time: string;
  latitude: number;
  longitude: number;
  time: number | null;
  delay: number | null;
  old: boolean;
}

export type Floor = "low_floor" | "low_entry" | "high_floor";

export interface Vehicle {
  full_kmk_id: string;
  category: Category | null;
  full_model_name: string | null;
  short_model_name: string | null;
  floor: Floor | null;
  gtfs_block_id: number | null;
  gtfs_trip_num: number | null;
  gtfs_service_id: string | null;
  gtfs_latitude: number | null;
  gtfs_longitude: number | null;
  gtfs_bearing: number | null;
  gtfs_timestamp: number | null;
  gtfs_route_short_name: string | null;
  gtfs_trip_headsign: string | null;
  gtfs_current_stop_name: string | null;
  gtfs_current_stop_sequence: number | null;
  gtfs_path: Path | null;
  gtfs_stop_times: StopTime[] | null;
  ttss_latitude: number | null;
  ttss_longitude: number | null;
  ttss_bearing: number | null;
  ttss_timestamp: number | null;
  ttss_route_short_name: string | null;
  ttss_trip_headsign: string | null;
  kokon_latitude: number | null;
  kokon_longitude: number | null;
  kokon_bearing: number | null;
  kokon_timestamp: number | null;
  kokon_route_short_name: string | null;
  kokon_trip_headsign: string | null;
  kokon_current_stop_name: string | null;
}

export function useVehicle(kmkId: string, interval: number) {
  const { cacheBuster } = useCacheBuster(interval);

  const { data, error } = useFetch<{ vehicle: Vehicle }>(
    `${BACKEND_BASE_URL}/api/vehicles/${kmkId}?t=${cacheBuster}`
  );

  const [vehicle, setVehicle] = useState<Vehicle | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data) {
      setVehicle(data.vehicle);
      setLoading(false);
    }
  }, [data]);

  return { vehicle, loading, error };
}
