import { useEffect, useState } from "react";
import { useFetch, useLocalStorage } from "usehooks-ts";

import { BACKEND_BASE_URL } from "../config";
import { compareRouteShortName } from "../utils";
import { useCacheBuster } from "./useCacheBuster";

export interface VehicleOnBlock {
  kmk_id: string;
  timestamp: number;
}

export interface Block {
  category: string;
  block_id: number;
  service_id: string;
  start_time: string;
  end_time: string;
  route_short_names: string[];
  is_current: boolean;
  vehicles_on_block: VehicleOnBlock[];
}

export interface Weekdays {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

export interface Service extends Weekdays {
  service_id: string;
}

function sortShortRouteNames(blocks: Block[]) {
  const copy = blocks.map((block) => {
    if (block.route_short_names.length === 1) {
      return block;
    }
    const copy = Array.from(block.route_short_names);
    copy.sort(compareRouteShortName);
    return { ...block, route_short_names: copy };
  });
  copy.sort((a, b) =>
    compareRouteShortName(a.route_short_names[0], b.route_short_names[0])
  );
  return copy;
}

function useFetchBlocks(interval: number) {
  const { cacheBuster } = useCacheBuster(interval);

  const { data, error } = useFetch<{ blocks: Block[]; services: Service[] }>(
    `${BACKEND_BASE_URL}/api/blocks?t=${cacheBuster}`
  );

  const [blocks, setBlocks] = useState<Block[]>([]);
  const [services, setServices] = useState<Service[]>([]);
  const [timestamp, setTimestamp] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data) {
      setBlocks(sortShortRouteNames(data.blocks));
      setServices(data.services);
      setTimestamp(Date.now());
      setLoading(false);
    }
  }, [data]);

  return { timestamp, blocks, services, loading, error };
}

export function useBlocks(interval: number) {
  const { timestamp, blocks, services, loading, error } =
    useFetchBlocks(interval);

  const [localStorage, setLocalStorage] = useLocalStorage<{
    blocks: Block[];
    services: Service[];
    timestamp: number;
  } | null>("blocks_v2", null);

  // update local storage after fetch
  useEffect(() => {
    if (!loading && timestamp) {
      setLocalStorage({ blocks, services, timestamp });
    }
  }, [timestamp, loading, setLocalStorage, blocks, services]);

  if (
    loading &&
    localStorage !== null &&
    Date.now() - localStorage.timestamp < 5 * 60_000
  ) {
    return { ...localStorage, loading: false, error: null };
  }

  return { timestamp, blocks, services, loading, error };
}
